import render from './adminNav.js';
import { manageClientBtns } from './manageClients.js';
import { manageProductBtns } from './manageProducts.js';
// import handleSearchDocs from './searchDocs.js';

const parentElement = document.getElementById('main-container');
const titleElement = document.getElementById('manage-title');

function handleSideBar(e) {
  const btnSideBar = e.target.closest('.side--bar__links__list');

  if (!btnSideBar || btnSideBar.classList.contains('nav-active')) return;

  const { id } = btnSideBar;

  passProps(id);
}

export function handleManagmentContainer(e) {
  const btnManage = e.target.closest('.btn-manage');

  const btnManagement = e.target.closest('.btn');
  const isBtnProductManagment = btnManagement && e.target.closest('.row-product');
  const isBtnClientManagment = btnManagement && e.target.closest('.row-user');
  const linkContainer = e.target.closest('.link-container');

  if (!btnManage && !isBtnProductManagment && !isBtnClientManagment && !linkContainer)
    return;

  if (btnManage) return passProps(btnManage.dataset.for);

  if (isBtnClientManagment) return manageClientBtns(btnManagement);

  if (isBtnProductManagment) return manageProductBtns(btnManagement);

  if (linkContainer && e.target.closest('.link'))
    return handleLinkCopy(e.target.closest('.link'));
}

function passProps(id) {
  if (!document.getElementById(id)) return;

  render(parentElement, titleElement, id);

  // Make the clicked btn active
  document
    .getElementById('admin-sidebar')
    .querySelectorAll('.nav-active')
    .forEach((navLink) => {
      navLink.classList.remove('nav-active');
    });

  document.getElementById(id)?.classList.add('nav-active');
}

function handleLinkCopy(link) {
  const copyStatus = link?.querySelector('.copy-status');

  const lastIndexOfClosing = link.textContent.lastIndexOf('>');

  const linkToBeCopied = link.textContent.substring(0, lastIndexOfClosing + 1);

  navigator.clipboard.writeText(linkToBeCopied);

  copyStatus && (copyStatus.innerHTML = '<i class="fa-solid fa-copy"></i> Copied!');
}

export { handleSearchDocs } from './searchDocs.js';
export default handleSideBar;
