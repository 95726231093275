import { showAlert } from '../reusables/alert';
import makeRequest from '../reusables/fetch';
import showError from '../reusables/showError';
import {
  generateClientMarkup,
  generateProductMarkup,
  //   markupLoading,
} from './markups';

let timeoutId;
let docName;
let prevVal = '';

const markupLoading = `
            <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        `;

const baseUrl = '/api/v1';

export async function handleSearchDocs(e) {
  const input = e.target.closest('.input-search-autocomplate');
  const docFor = (docName = input.dataset.for);
  const { isOnClient, toClient } = input.dataset;

  const name = e.target.value;
  const table = document.getElementById(`table-${docFor}`);

  clearTimeout(timeoutId);

  timeoutId = setTimeout(async () => {
    if (prevVal === name) return;
    prevVal = name;

    const renderOpts = {
      isOnClient: isOnClient === 'true' ? true : false,
      toClient: toClient === 'true' ? true : false,
    };

    // Query Opt
    const opts = {
      url: `${baseUrl}/${docFor}/${name ? 'text-search' : ''}`,
      dataTobeSent: { name },
      method: name ? 'post' : 'get',
    };

    const fun = docFor === 'users' ? generateClientMarkup : generateProductMarkup;

    try {
      table.innerHTML = markupLoading;

      const { data } = await makeRequest(opts);

      const docs = name === '' ? data.products || data.documents : data.docs;

      table.innerHTML =
        docs?.length > 0
          ? docs.map((doc, i) => fun({ doc, i, ...renderOpts })).join('')
          : '<h5>No document found.</h5>';
    } catch (err) {
      showError(err);
    }
  }, 1000);
}
