const prompts = {
  hello: `Bonjour, je suis la pour répondre à vos questions.
Veuillez choisir si vous souhaitez effectuer une recherche par référence du produit ou avec le nom du produit.
`,
  foundOneByReference: `Great news! I've successfully located a product called {PRODUCT} by {BRAND}. Would you like to proceed? \n\n If this is not the product you were looking please click check by name button so I can look it up by its name.`,
  foundMultipleByReference:
    'Looks like we have multiple products matching the provided reference. To assist you better, please select the brand of your product from the dropdown menu below.',
  foundNonByReference:
    'Je suis désolé, mais aucun produit ne correspond à cette référence : {REFERENCE}. Essayez avec une description du produit ou grâce aux noms du produit.',
  foundOneByReferenceAndBrand: `J'ai trouvé ! Le produit {PRODUCT} par {BRAND} est-il le produit que vous recherchez ? Si Oui veuillez cliquer sur OK, si ce n'est pas le produit que vous recherchez veuillez cliquer sur Suggerer un autre produit.`,
  provideReference: `Très bien ! Veuillez entrer référence de votre produit. Attention, veillez à bien renseigner le référence exacte du produit recherché.`,
  foundOneByName: `J'ai trouvé ! Le produit {PRODUCT} par {BRAND} est-il le produit que vous recherchez ? Si Oui veuillez cliquer sur OK, si ce n'est pas le produit que vous recherchez veuillez cliquer sur Suggerer un autre produit.`,
  foundFewByName:
    'Looks like we have few products matching the name: {ENTERED_NAME}. Please select the product that is most likly be yours.',
  foundMultipleByName:
    'Looks like we have few products matching the name: {ENTERED_NAME}. Please select the product that is most likly be yours.',
  foundLotByName:
    'Looks like we have few products matching the name: {ENTERED_NAME}. Please select the product that is most likly be yours.',
  foundOneByNameAndBrand:
    "This product is {PRODUCT_DESCRIPTION}. Would you like to proceed? If you would like to suggest your product click 'Suggest Product'.",
  foundNonByName:
    'Apologies, no product matches the Name: {ENTERED_NAME}. You can click on update name and check with the name on your product description.',
  provideName: `Très bien ! Veuillez entrer le nom de votre produit. Attention, veillez à bien renseigner le nom du produit recherché. \n\n The product search accuracy depend on your name input, so make sure to type full name!`,
  proceedWithProduct: `C'est parti ! N'hésitez pas à me poser vos questions sur ce produit.`,
};

export default prompts;
