import chat from './chat/chat.js';
import { handleLogin, handleLogout } from './login.js';
import handleSignup from './signup.js';
import handleForgot from './forgotPassowrd.js';
import handleSideBar, {
  handleManagmentContainer,
  handleSearchDocs,
} from './admin/adminController.js';
import { initClientManagment } from './admin/manageClients.js';
import { initProductManagment } from './admin/manageProducts.js';
import initStat from './client/requestStat.js';
import handlePagination from './admin/paginate.js';

const signup = document.querySelector('.signup-form');
const login = document.querySelector('.login-form');
const logout = document.querySelector('.btn-logout');
const forgotPassword = document.getElementById('forgot-password');
const passowrd = document.getElementById('password-container');
const btnLoginNow = document.querySelector('.btn-loginnow');
const chartCanva = document.getElementById('chart');

login?.addEventListener('submit', handleLogin);
logout?.addEventListener('click', handleLogout);
signup?.addEventListener('submit', handleSignup);

forgotPassword?.addEventListener('click', (e) => {
  e.preventDefault();
  passowrd.remove();
  forgotPassword.remove();
  btnLoginNow.textContent = 'Send Reset Link';

  // romobing already existing event with new one
  login.removeEventListener('submit', handleLogin);
  login.addEventListener('submit', handleForgot);
});

chartCanva && window.addEventListener('load', initStat.bind(chartCanva));

// ------------- Admin
const adminSideBar = document.getElementById('admin-sidebar');
const managmentContainer = document.getElementById('main-container');
const searchInput = document.querySelector('.input-search-autocomplate');
const paginationContainer = document.getElementById('paginate-documents');

paginationContainer?.addEventListener('click', handlePagination);
adminSideBar?.addEventListener('click', handleSideBar);
managmentContainer?.addEventListener('click', handleManagmentContainer);
searchInput?.addEventListener('input', handleSearchDocs);

initProductManagment();
initClientManagment();
