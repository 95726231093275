import showError from '../reusables/showError.js';
import { showProgress, removeProgress } from '../reusables/showProgressBtn.js';
import { showAlert } from '../reusables/alert.js';
import makeRequest from '../reusables/fetch.js';

export default async function handleUpload({
  fileContainer,
  isFile = false,
  input,
  btn,
  url,
}) {
  input.setAttribute('disabled', true);
  const file = isFile ? fileContainer : fileContainer.dataTransfer.items[0].getAsFile();
  uploadFile({ file, input, btn, url });
}

// /////////////////// //
//      HELPERS        //
// ////////////////// //

// --------------- from pdf
async function extractTextFromPdf(file) {
  const typedArray = new Uint8Array(await file.arrayBuffer());
  const pdfDocument = await pdfjsLib.getDocument({ data: typedArray }).promise;

  const textContent = [];

  // if (pdfDocument.numPages > 50) throw new Error('Please dont use large pdfs. Thank you');

  for (let i = 1; i <= pdfDocument.numPages; i++) {
    const page = await pdfDocument.getPage(i);
    const pageContent = await page.getTextContent();
    textContent.push(pageContent);
  }

  const text = textContent.map((content) => {
    return content.items.map((item) => item.str).join('');
  });
  return text.join('');
}

// ------------ form txt
async function extractTextFromTxt(file) {
  const text = await file.text();

  return text;
}

/////////////////l//////////////////////
export async function uploadFile({ file, url, input, btn }) {
  const fileReader = new FileReader();

  fileReader.onload = async function () {
    try {
      input && input.setAttribute('disabled', true);
      // progress indicators
      const { type } = file;
      let text;
      let document;

      switch (type) {
        case 'text/csv':
        case 'application/epub+zip':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
          document = handleOtherfiles(file);
          break;

        case 'application/pdf':
          text = await extractTextFromPdf(file);
          break;

        case 'text/plain':
          text = await extractTextFromTxt(file);
          break;

        default:
          throw new Error(`This file format ${type} is not supported.`);
      }

      const dataTobeSent = document
        ? document
        : {
            text,
            originalName: file.name,
          };

      const data = await makeRequest({
        dataTobeSent,
        method: 'post',
        url,
      });

      // Progress Indicators
      showAlert('success', 'Successful on uploading your document!');
      removeProgress(
        btn,
        `Successful <input id='add-file' type="file", hidden='', accept='.pdf,.txt, .doc,.docx, .epub'/>`
      );

      setTimeout(() => {
        btn.innerHtml = `Add Document <input id='add-file' type="file", hidden='', accept='.pdf,.txt'/>`;
      });
      input && input.removeAttribute('disabled');
    } catch (err) {
      removeProgress(btn, `Try Again`);
      input && input.removeAttribute('disabled');

      const message = err.response?.data?.message || err.response?.data || err.message;
      showAlert('danger', message);
    }
  };
  fileReader.readAsArrayBuffer(file);
}

function handleOtherfiles(file) {
  const form = new FormData();
  form.append('document', file);

  return form;
}
