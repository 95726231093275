import showError from './reusables/showError.js';
import { showAlert } from './reusables/alert.js';
import { showProgress, removeProgress } from './reusables/showProgressBtn.js';

async function handleSignup(e) {
  e.preventDefault();
  const btn = document.getElementById('btn-signup');
  const btnModal = document.getElementById('vefiryEmail');
  try {
    const dataObj = {};

    const inputFields = e.target.querySelectorAll("input:not([type='checkbox])");

    for (const inpt of inputFields) {
      dataObj[inpt.name] = inpt.value;
    }

    showProgress(btn);

    const { data } = await axios({
      method: 'post',
      url: '/api/v1/users/signup',
      data: dataObj,
    });

    removeProgress(btn, 'Success');
    btnModal.click();

    showAlert('primary', 'Success. Enjoy our website.');

    // REDIRECT
    setTimeout(() => {
      document.body.click();
      location.assign('/login');
    }, 3000);
  } catch (err) {
    showError(err, btn, 'REGISTER NOW!');
  }
}

export default handleSignup;
