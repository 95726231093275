import makeRequest from '../reusables/fetch.js';

export const markupLoading = `
        <div class='loading-container'>
            <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>`;

// --------------- Table Container
export const markupTableContainer = async (id, opts) => {
  const isOnClient = opts?.isOnClient || false;
  const toClient = opts.toClient || false;

  // brand renderer
  const renderBrands = async () => {
    const {
      data: { brands },
    } = await makeRequest({ url: '/api/v1/products/brands' });
    brands.push({ _id: 'all' });

    const brandList = brands
      .map(
        (brand) =>
          `<li><a class='btn switch-brand-filter' data-value=${brand._id}>${
            brand._id.slice(0, 1).toUpperCase() + brand._id.slice(1).toLowerCase()
          }</a></li>`
      )
      .join('');

    return `<div class='btn dropdown-toggle btn-outline-primary dropdown-brand-filter' data-bs-toggle="dropdown" aria-expanded="false">All</div>
            <ul class='dropdown-menu' id='brand-dorpdown-filter'>
              ${brandList}
            </ul>`;
  };

  return `
        <section class='features py-6 bg-white px-3 px-lg-0'>
        <div class='tool-container'>
            <div class='btn-create-new' data-bs-toggle='modal' data-bs-target='#create${
              id.toLowerCase() === 'users' ? 'Client' : 'Product'
            }Modal'>Create New ${id === 'users' ? 'Client' : 'Product'} </div>
            <input class='input-search-autocomplate' type='text' placeholder='Search...' 
              data-for=${id} 
              data-is-on-client='${id === 'products' && isOnClient ? true : false}'
              data-to-client='${id === 'products' && toClient ? true : false}'
            '/>
            ${id.toLowerCase() === 'products' ? await renderBrands() : ''}
        </div>
            <div class='container'>
                <div class='table-responsive'>
                    <table class='table table-hover align-middle'>
                        <thead>
                            <tr>
                                ${thead[id]}
                            </tr>
                        </thead>
                        <tbody  id='table-${id}'></tbody>
                    </table>
                </div>
            </div>
        </section>
      
        `;
};

// ----------- Markup heads
export const thead = {
  users: `
        <th scope='col' class='table-id'>ID</th>
        <th scope='col'>Bussiness Name</th>
        <th scope='col'>Contact Person</th>
        <th scope='col'>Email Address</th>
        <th scope='col'>ChatBot</th>
        <th scope='col'>Total Products</th>
        <th scope='col'>Approved</th>
        <th scope='col'>Actions</th>
        `,
  products: `
        <th scope='col'>ID</th>
        <th scope='col'>Name</th>
        <th scope='col'>Brand</th>
        <th scope='col'>Enabled</th>
        <th scope='col'>Queried</th>
        <th scope='col'>Actions</th>
  `,
};

// ---------- Markup for clients
export function generateClientMarkup({ doc: client, i }) {
  return `
    <tr class='row-user' data-user='${JSON.stringify(client)}'>
        <th scope='row'>${i !== undefined ? i + 1 : ''}</th>
        <td  class='bussiness-name'>${client.name}</td>
        <td class='contact-name'>${client.contactName || ''}</td>
        <td class='email'>${client.email}</td>
        <td class='email-verified'>${
          client.chatbotWithVoice === true ? 'With Voice' : 'Classic'
        }</td>
        <td class='total-products'>${client.products.length}</td>
        <td class='Approved'>${client.approved}</td>
        <td>
            <a class='btn' id='edit-client' data-bs-toggle='modal' data-bs-target='#editModal'>
                <i class='bi bi-pencil'></i> Edit
            </a>
            <a class='btn btn-danger' id='btn-delete-client' data-bs-toggle='modal' data-bs-target='#deleteUserModal'>
                <i class='bi bi-person-x'></i> Delete
            </a>
        </td>
    </tr>
  `;
}

// ------------ Markup for products
export function generateProductMarkup({
  doc: product,
  i,
  isOnClient = false,
  toClient = false,
}) {
  const actions = toClient
    ? `<a class='btn btn-info' id='btn-add-product'>Add</a>`
    : ` <a class='btn' id='btn-add-manual' data-bs-toggle='modal' data-bs-target='#addDocumentModal'>
                Add Manual
            </a>
            <a class='btn' id='edit-product' data-bs-toggle='modal' data-bs-target='#editProductModal'>
                Edit
            </a>
            <a class='btn btn-danger' id='btn-${
              isOnClient ? 'remove' : 'delete'
            }-product' data-bs-toggle='modal' data-bs-target='#${
        isOnClient ? 'remove' : 'delete'
      }ProductModal'>
               ${isOnClient ? 'Remove' : 'Delete'}
            </a>`;

  const markupTable = `
    <tr class='row-product' data-product='${
      toClient ? product._id : JSON.stringify(product)
    }'>
        <th scope='row'>${i + 1}</th>
        <td class='product-name'>${product.name}</td>
        <td class='product-brand'>${product.brand}</td>
        <td class='product-enabled'>${product.enabled}</td>
        <td class='product-queried'>${product.queried}</td>

        
        <td>
           ${actions}            
        </td>
    </tr>
    `;

  return markupTable;
}

// ------------- Dashboard Markup
export function generateDashboardMarkup(stat) {
  const dataFor =
    stat.title.toLowerCase() === 'clients' ? 'users' : stat.title.toLowerCase();

  return `
        <div class='admin--dashboard__card'>
            <h4>${stat.title}</h4>
            <h3>${stat.value}</h3>
            <button class='custom-button btn-manage' data-for='${dataFor}'>Manage</button>
        </div>`;
}

// --------------- Stat Markup
export function generateStatMarkup() {
  const innerHtml = `
        <div>
          <label for='year-select'>Select Year</label>
          <select id='year-select'></select>
          <i class='bi bi-caret-down-fill'></i>
        </div>
        <canvas id='chart'></canvas>
        `;
}

// -------------- Page markup
export const prevBtnHtml = (page, docType) =>
  page > 1
    ? `
      <div class='page-item'>
        <div class='btn page-link' data-to-page=${
          +page - 1
        } data-for-doc='${docType}' >Prev Page</div>
      </div>
    `
    : '';

export const nextBtnHtml = (page, numOfPages, docType) =>
  page < numOfPages
    ? `
      <div class='page-item'>
        <div class='btn page-link' data-to-page=${
          +page + 1
        } data-for-doc='${docType}' >Next Page</div>
      </div>
            `
    : '';

export const currentPageHtml = (page, numOfPages) =>
  page > 1
    ? `
      <button class='btn' disabled >${page}</button>`
    : '';

export function markupPage({ page, numOfPages, docType }) {
  const prevPage = prevBtnHtml(page, docType);
  const currentPage = currentPageHtml(page, numOfPages);
  const nextPage = nextBtnHtml(page, numOfPages, docType);

  const markupPage = `
   <nav aria-label="Page navigation example" id='paginate-documents' >
    <div class='pagination justify-content-center fw-bold fs-5 text-purple'>
      ${prevPage} ${currentPage} ${nextPage}
    </div>
   </nav>
  `;

  return markupPage;
}
