import { showAlert } from '../reusables/alert.js';
import { showProgress, removeProgress } from '../reusables/showProgressBtn.js';
import showError from '../reusables/showError.js';
import makeRequest from '../reusables/fetch.js';

// Reusable
function getSetDocument({ btn, modal, row }) {
  // Put client data on the modal
  const documentRow = btn.closest(`.row-${row}`);
  const documentStr = documentRow.dataset[row];

  // extract user data
  const document = JSON.parse(documentStr);

  modal.dataset[row + 'Id'] = document._id;
  modal.querySelector('.doc-name').innerHTML = document.name;

  return { document, documentRow };
}

export async function hanldeDocumentCreation({ btn, url, form }) {
  const innerHTML = btn.innerHTML;

  try {
    const dataTobeSent = {};
    let isEmpity = false;

    const inps = [
      ...form.querySelectorAll('input'),
      ...form.querySelectorAll('textarea'),
      ...form.querySelectorAll('select'),
    ];

    inps.forEach((inp) => {
      if (inp.value.trim() === '') isEmpity = true;

      dataTobeSent[inp.id.split('-')[1]] = inp.value.trim();
    });

    if (isEmpity) showAlert('danger', 'All input fields are required');

    showProgress(btn);
    const doc = await makeRequest({ url, dataTobeSent, method: 'post' });
    removeProgress(btn, 'Successful');

    showAlert('success', 'Successful on document creation.');

    setTimeout(() => {
      btn.innerHTML = innerHTML;
    }, 1500);

    return doc;
  } catch (err) {
    throw err;
  }
}

// -------------- Document Delete Handler
export async function handleDocumentDelete({
  btn,
  url,
  documentRow,
  method = 'delete',
  dataTobeSent,
}) {
  const htmlDelete = btn.innerHTML;

  try {
    showProgress(btn);
    const opt = {
      url,
      method: method ? method : 'delete',
      dataTobeSent: dataTobeSent || undefined,
    };

    await makeRequest({
      url,
      method: method ? method : 'delete',
      dataTobeSent,
    });

    removeProgress(btn, 'Success');
    documentRow.remove();
    showAlert('success', `Successful on document ${method ? 'removal' : 'deletion'}`);

    setTimeout(() => {
      btn.innerHTML = htmlDelete;
    }, 1500);
  } catch (err) {
    showError(err, btn, htmlDelete);
  }
}

export function closeModal(btn) {
  btn.closest('.modal')?.querySelector('.btn-close')?.click();
}

export default getSetDocument;
