import showError from '../reusables/showError.js';
import {
  showProgress,
  removeProgress,
  showSuccessUpdate,
} from '../reusables/showProgressBtn.js';
import { showAlert } from '../reusables/alert.js';
import makeRequest from '../reusables/fetch.js';
import getSetClient, {
  closeModal,
  handleDocumentDelete,
  hanldeDocumentCreation,
} from './modalReusable.js';

const editModal = document.getElementById('editModal');
const deleteUserModal = document.getElementById('deleteUserModal');
const formCreateClient = document.querySelector('.form-create-client');
const adminSideBar = document.getElementById('admin-sidebar');
const btnClients = adminSideBar?.querySelector('#users');

const nameIn = editModal?.querySelector('#name');
const contactNameIn = editModal?.querySelector('#contactName');
const emailIn = editModal?.querySelector('#email');
const approvedIn = editModal?.querySelector('#approved');
const roleIn = editModal?.querySelector('#role');
const withVoiceIn = editModal?.querySelector('#chatbotWithVoice');
const chatBotLinkIn = editModal?.querySelector('#chatbot-link');
const passwordIn = editModal?.querySelector('#password');

let user;
let userRow;
const baseUrl = '/api/v1/users';

// ------------ Deligate tasks to diff functions
export function manageClientBtns(btn) {
  const handle = ({ btn, modal }) => {
    const opt = { btn, modal, row: 'user' };
    const { document, documentRow } = getSetClient(opt);

    userRow = documentRow;
    user = document;
    modal
      .querySelector('#manage-client-products')
      ?.setAttribute('href', `/admin/client-products/${user._id}`);
  };

  const btnEdit = btn.closest('#edit-client');
  const btnDelete = btn.closest('#btn-delete-client');

  if (!btnEdit && !btnDelete) return;

  if (btnEdit) {
    handle({ btn: btnEdit, modal: editModal });
    return handleClientModal();
  }

  if (btnDelete) return handle({ btn: btnDelete, modal: deleteUserModal });
}

async function handleClientCreation(e) {
  e.preventDefault();

  const submitBtn = formCreateClient.querySelector('.btn-create');
  try {
    const opt = {
      url: baseUrl,
      form: formCreateClient,
      btn: submitBtn,
    };

    const { data } = await hanldeDocumentCreation(opt);

    await makeRequest({
      url: `${baseUrl}/${data.doc._id}`,
      method: 'patch',
      dataTobeSent: { emailVerified: true, approved: true },
    });

    btnClients.classList.remove('nav-active');
    btnClients.click();

    setTimeout(() => {
      closeModal(submitBtn);
    }, 1500);
  } catch (err) {
    showError(err, submitBtn, 'Create New Client');
  }
}

export function handleClientModal() {
  // populate modal inputs with client data
  nameIn.value = user.name;
  contactNameIn.value = user.contactName;
  emailIn.value = user.email;
  approvedIn.value = user.approved === false ? 'false' : 'true';
  withVoiceIn.value = user.chatbotWithVoice === true ? 'true' : 'false';
  roleIn.value = user.role;
  chatBotLinkIn.value = `api/v1/users/bigchatbot/${user.slug}`;
}

export async function handleClientUpdate(e) {
  const btnUpdate = e.target.closest('.btn-update-input');

  if (!btnUpdate) return;

  const { userId } = editModal.dataset;
  const inputContainer = btnUpdate.closest('.update-input-container');

  const updateInput =
    inputContainer.querySelector('input') || inputContainer.querySelector('select');

  const dataTobeSent = {
    [updateInput.id]: updateInput.value.trim() || user[updateInput.id],
  };

  const url = `${baseUrl}/${userId}`;
  try {
    // show progress
    showProgress(btnUpdate, ' ');

    // update
    const { data } = await makeRequest({
      url,
      dataTobeSent,
      method: 'patch',
    });

    user = data.updatedDoc;
    userRow.dataset.user = JSON.stringify(data.updatedDoc);

    showSuccessUpdate(btnUpdate);
  } catch (err) {
    showError(err, btnUpdate, 'Update');
  }
}

// -------------- Client Delete Handler
export async function handleUserDelete(e) {
  const btnDeleteUser = e.target.closest('.btn-delete-yes');

  if (!btnDeleteUser) return;

  await handleDocumentDelete({
    btn: btnDeleteUser,
    url: `${baseUrl}/${deleteUserModal.dataset.userId}`,
    documentRow: userRow,
  });

  closeModal(btnDeleteUser);
}

export function initClientManagment() {
  editModal?.addEventListener('click', handleClientUpdate);
  deleteUserModal?.addEventListener('click', handleUserDelete);
  formCreateClient?.addEventListener('submit', handleClientCreation);
}
