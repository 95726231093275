import { getClientDocs, getDocs } from '../reusables/fetch.js';

import {
  generateClientMarkup,
  generateDashboardMarkup,
  generateProductMarkup,
  thead,
  markupTableContainer,
  // generateStatMarkup,
  markupPage,
  prevBtnHtml,
  currentPageHtml,
  nextBtnHtml,
} from './markups.js';

const markupLoading = `
            <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        `;

const baseUrl = '/api/v1';
let manage;

async function handlePagination(e) {
  const btnPage = e.target.closest('.page-link');
  const brand = document
    .querySelector('.dropdown-brand-filter')
    ?.textContent.toLowerCase();

  if (!btnPage) return;

  let page, numOfPages, docs;
  const { toPage, forDoc } = btnPage.dataset;
  manage = forDoc;

  const parentElement = document.getElementById(
    `table-${forDoc === 'users' ? 'users' : 'products'}`
  );

  try {
    parentElement.innerHTML = markupLoading;

    if (forDoc === 'users' || forDoc.includes('products')) {
      let data;
      if (forDoc === 'users' || forDoc === 'products')
        data = await getDocs({
          manage: forDoc,
          opts: { params: { page: toPage, brand: brand === 'all' ? undefined : brand } },
        });

      if (forDoc.includes('-products'))
        data = await getClientDocs({
          manage: forDoc,
          opts: { params: { page: toPage, brand: brand === 'all' ? undefined : brand } },
        });

      page = data.page;
      numOfPages = data.numOfPages;
      docs = data.products || data.documents;

      updatePagination({ btn: btnPage, page, numOfPages, forDoc });
    }
    if (forDoc !== manage) return;

    let rowMarkups;
    switch (forDoc) {
      case 'users':
        rowMarkups = docs.map((doc, i) => generateClientMarkup({ doc, i })).join('');
        break;
      case 'products':
        rowMarkups = docs
          .map((product, i) => generateProductMarkup({ doc: product, i }))
          .join('');
        break;
      case 'client-products':
        rowMarkups = docs
          .map((product, i) =>
            generateProductMarkup({ doc: product, i, isOnClient: true })
          )
          .join('');
        break;
      case 'add-products':
        rowMarkups = docs
          .map((product, i) => generateProductMarkup({ doc: product, i, toClient: true }))
          .join('');
        break;
      case 'default':
        break;
    }

    parentElement.innerHTML = rowMarkups;
  } catch (err) {}
}

export function updatePagination({ page, numOfPages, btn, forDoc }) {
  const paginationContainer = btn
    ? btn.closest('.pagination')
    : document.querySelector('.pagination');

  paginationContainer.innerHTML = `
        ${prevBtnHtml(page, forDoc)} ${currentPageHtml(page, numOfPages)} ${nextBtnHtml(
    page,
    numOfPages,
    forDoc
  )}
  `;
}

export default handlePagination;
