import makeRequest from '../reusables/fetch.js';

const baseUrl = '/api/v1/users';

async function initStat(client) {
  const {
    data: { totalQueries, topFive },
  } = await makeRequest({
    url: `${baseUrl}/${client?._id ? 'client-' : ''}products-stat/${
      client?._id ? client._id : ''
    }`,
  });

  document
    .getElementById('main-container')
    ?.querySelector('.loading-container')
    ?.remove();

  document.getElementById(
    'total-queries'
  ).innerHTML = `<h5>Your products are queried a total times of: ${totalQueries}</h5> The following pie chart includes the top five queried products and the sum of all others.`;
  const queryOnOthers = totalQueries - topFive.reduce((acc, pro) => acc + pro.queried, 0);

  const chart = new Chart(document.getElementById('chart'), {
    type: 'pie',
    data: {
      labels: ['Other Products', ...topFive.map((product) => product.name)], // To be filled dynamically
      datasets: [
        {
          label: 'Requested',
          data: [queryOnOthers, ...topFive.map((product) => product.queried)], // To be filled dynamically
          backgroundColor: [
            'rgba(247,103,7,1)',
            'rgba(139, 57, 174,1)',
            'rgba(250,82,82,1)',
            'rgba(255,212,59,1)',
            'rgba(18,184,134,1)',
            'rgba(28,126,214,1)',
          ],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    },
  });
}

export default initStat;
