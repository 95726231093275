import showError from '../reusables/showError.js';
import { showAlert } from '../reusables/alert.js';
import {
  removeProgress,
  showProgress,
  showSuccessUpdate,
} from '../reusables/showProgressBtn.js';
import makeRequest from '../reusables/fetch.js';
import getSetProduct, {
  closeModal,
  handleDocumentDelete,
  hanldeDocumentCreation,
} from './modalReusable.js';
import handleUpload from './upload.js';
import { generateProductMarkup } from './markups.js';
import { updatePagination } from './paginate.js';

let editProductModal = document.getElementById('editProductModal');
let uploadModal = document.getElementById('addDocumentModal');
const deleteProductModal = document.getElementById('deleteProductModal');
const removeProductModal = document.getElementById('removeProductModal');
const formCreateProduct = document.getElementById('form-create-product');
const adminSideBar = document.getElementById('admin-sidebar');
const btnProducts = adminSideBar?.querySelector('#products');

const nameIn = editProductModal?.querySelector('#product-name');
const brandIn = editProductModal?.querySelector('#product-brand');
const descriptionIn = editProductModal?.querySelector('#product-description');
const productReferenceIn = editProductModal?.querySelector('#product-productReference');
const manualsIn = editProductModal?.querySelector('#product-manuals');
const dropdownBrand = editProductModal?.querySelector('.dropdown-brand');
let dropdownBrandFilter = document.getElementById('brand-dorpdown-filter');

let product;
let productRow;
const baseUrl = '/api/v1/products';

// --------------- Product managment btn manager
export function manageProductBtns(btn) {
  const handle = ({ btn, modal }) => {
    const opt = { btn, modal, row: 'product' };
    const { document, documentRow } = getSetProduct(opt);

    productRow = documentRow;
    product = document;
  };

  const btnEdit = btn.closest('#edit-product');
  const btnUpload = btn.closest('#btn-add-manual');
  const btnDelete = btn.closest('#btn-delete-product');
  const btnAdd = btn.closest('#btn-add-product');
  const btnRemove = btn.closest('#btn-remove-client-product');

  if (!btnEdit && !btnUpload && !btnDelete && !btnAdd && !btnRemove) return;

  if (btnEdit) {
    handle({ btn: btnEdit, modal: editProductModal });
    return handleProductModal();
  }

  if (btnUpload) return handle({ btn: btnUpload, modal: uploadModal });

  if (btnDelete) return handle({ btn: btnDelete, modal: deleteProductModal });

  if (btnRemove) return handle({ btn: btnRemove, modal: removeProductModal });

  if (btnAdd) return handleBtnAddProduct(btnAdd);
}

// ------------ Add Product To Client
async function handleBtnAddProduct(btnAdd) {
  // console.log(btnAdd);
  const { product } = btnAdd.closest('.row-product').dataset;
  const userId = location.pathname.split('client-products/')[1];

  try {
    showProgress(btnAdd, ' ');
    const res = await makeRequest({
      url: `/api/v1/users/${userId}/products/add-product/${product}`,
      method: 'patch',
    });
    // console.log(res);
    btnAdd.remove();
    showAlert('success', 'Succes on adding a product product.');
  } catch (err) {
    showError(err, btnAdd, 'Add');
  }
}

// --------------- Create Product Handler
async function handleProductCreation(e) {
  e.preventDefault();

  const submitBtn = formCreateProduct.querySelector('.btn-create');

  const { userId } = e.target.closest('#createProductModal').dataset;

  try {
    const opt = {
      url: userId ? `/api/v1/users/${userId}/products` : baseUrl,
      form: formCreateProduct,
      btn: submitBtn,
    };

    const doc = await hanldeDocumentCreation(opt);

    btnProducts.classList.remove('nav-active');
    btnProducts.click();

    setTimeout(() => {
      closeModal(submitBtn);
    }, 1500);
  } catch (err) {
    showError(err, submitBtn, 'Create New Product');
  }
}

// --------------- product edit handler
function handleProductModal() {
  // populate modal inputs with client data
  dropdownBrand.innerText = product.brand;
  nameIn.value = product.name;
  brandIn.value = product.brand;
  descriptionIn.value = product.description;
  productReferenceIn.value = product.productReference;
  manualsIn.value = product.manuals;
}

// --------------- Product update handler
export async function handleProductUpdate(e) {
  const btnUpdate = e.target.closest('.btn-update-input');
  const switchBrand = e.target.closest('#switch-brand');

  if (!btnUpdate && !switchBrand) return;

  if (switchBrand) return handleBrandSwitch(switchBrand);

  const { productId } = editProductModal.dataset;
  const inputContainer = btnUpdate.closest('.update-input-container');

  const updateInput =
    inputContainer.querySelector('input') || inputContainer.querySelector('select');

  const key = updateInput.id.split('-')[1];

  const dataTobeSent = {
    [key]: updateInput.value.trim() || user[key],
  };

  const url = `${baseUrl}/${productId}`;

  try {
    // show progress
    showProgress(btnUpdate, ' ');

    // update
    const { data } = await makeRequest({
      url,
      dataTobeSent,
      method: 'patch',
    });

    product = data.updatedDoc;
    productRow.dataset.product = JSON.stringify(data.updatedDoc);

    showSuccessUpdate(btnUpdate);
  } catch (err) {
    showError(err, btnUpdate, 'Update');
  }
}

// ------------------------ Brand Switch
export function handleBrandSwitch(switchBrand) {
  if (!switchBrand) return;
  const inputcontainer = switchBrand.closest('.update-input-container');

  inputcontainer.querySelector('input').value = switchBrand.dataset.value;
  dropdownBrand.innerText = switchBrand.dataset.value;
}

// --------------- Product upload handler
export function handleManualUpload(e) {
  const btnUpload = e.target.closest('#btn-upload');

  if (!btnUpload) return;

  const input = uploadModal.querySelector('#document');

  if (!input.value) return showAlert('danger', 'File is required.');

  if (input.files[0]) {
    handleUpload({
      fileContainer: input.files[0],
      isFile: true,
      input,
      btn: btnUpload,
      url: baseUrl + `/${product._id}`,
    });
  }

  showProgress(btnUpload);
}

// -------------- Product Delete Handler
export async function handleProductRemoveDelete(e) {
  const btnDeleteProduct = e.target.closest('.btn-delete-yes');

  if (!btnDeleteProduct) return;
  const userId = location.pathname.split('client-products/')[1];
  const isRemove = e.target.closest('#removeProductModal');
  const url = `${baseUrl}/${isRemove ? 'remove-clients/' : ''}${
    e.target.closest('.modal').dataset.productId
  }`;
  const method = isRemove && 'patch';
  const dataTobeSent = isRemove && { clients: userId };

  await handleDocumentDelete({
    btn: btnDeleteProduct,
    documentRow: productRow,
    url,
    dataTobeSent,
    method,
  });

  closeModal(btnDeleteProduct);
}

// ------------------ Filter by brand()
async function handleFilterByBrand(e) {
  const tableProduct = document.getElementById('table-products');

  const btnBrand = e.target.closest('.switch-brand-filter');
  if (!btnBrand) return;

  const btnToggle = btnBrand.closest('.tool-container').querySelector('.dropdown-toggle');

  const brand = btnBrand.dataset.value;

  if (btnToggle.innerText.toLowerCase().trim() === brand.trim()) return;
  try {
    showProgress(btnToggle);

    const { products, page, numOfPages } = await getFilteredProducts({
      params: { brand: brand === 'all' ? undefined : brand },
    });

    tableProduct.innerHTML = '';

    products.forEach((product, i) => {
      tableProduct.insertAdjacentHTML(
        'beforeend',
        generateProductMarkup({ doc: product, i })
      );
    });

    updatePagination({ page, numOfPages, forDoc: 'products' });

    btnToggle.innerText = brand.slice(0, 1).toUpperCase() + brand.slice(1);
    showAlert('success', `Now displaying products with brand ${brand}`);
    removeProgress(btnToggle, brand.slice(0, 1).toUpperCase() + brand.slice(1));
  } catch (err) {}
}

// ------------------ Product Filter
async function getFilteredProducts({ url = baseUrl, params = { brand: 'samsung' } }) {
  const {
    data: { products, page, numOfPages },
  } = await makeRequest({ url, opts: { params } });

  return { products, page, numOfPages };
}

export function initProductManagment() {
  uploadModal?.removeEventListener('click', handleManualUpload);
  editProductModal?.removeEventListener('click', handleProductUpdate);
  deleteProductModal?.removeEventListener('click', handleProductRemoveDelete);
  removeProductModal?.removeEventListener('click', handleProductRemoveDelete);
  formCreateProduct?.removeEventListener('submit', handleProductCreation);

  dropdownBrandFilter?.removeEventListener('click', handleFilterByBrand);

  dropdownBrandFilter = document.getElementById('brand-dorpdown-filter');

  uploadModal?.addEventListener('click', handleManualUpload);
  editProductModal?.addEventListener('click', handleProductUpdate);
  deleteProductModal?.addEventListener('click', handleProductRemoveDelete);
  removeProductModal?.addEventListener('click', handleProductRemoveDelete);
  formCreateProduct?.addEventListener('submit', handleProductCreation);
  dropdownBrandFilter?.addEventListener('click', handleFilterByBrand);
}
