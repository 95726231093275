//this function is responsible for API request with openAI backend-with endpoint of completions
async function makeRequest({ dataTobeSent = undefined, method = 'get', url, opts }) {
  try {
    const { data } = await axios({
      method,
      url,
      data: dataTobeSent,
      ...opts,
    });

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

const baseUrl = '/api/v1';

// ------------- get docs
export async function getDocs({ opts = {}, manage }) {
  opts.params = opts.params || {};
  opts.params.sort = '-createdAt';

  const { data } = await makeRequest({
    url: `${baseUrl}/${manage}`,
    opts,
  });

  return data;
}

// --------------- Client Docs
export async function getClientDocs({ opts = {}, manage }) {
  opts.params = opts.params || {};
  opts.params.sort = '-createdAt';
  const userId = location.pathname.split('client-products/')[1];

  const url = `${baseUrl}/users/${userId}/products${
    manage === 'add-products' ? '/other-products' : ''
  }`;

  const { data } = await makeRequest({
    url,
    opts,
  });

  return data;
}

export default makeRequest;
