let audioBuffer;

async function getAudio(text) {
  // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  let source, blobUrl;
  const response = await fetch('/api/v1/speech', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text,
    }),
  });
  const data = await response.arrayBuffer();

  const blob = new Blob([data], { type: 'audio/mpeg' });

  // Create a URL for the Blob and set it as the source for the audio element
  blobUrl = URL.createObjectURL(blob);

  return blobUrl;
}

export default getAudio;
