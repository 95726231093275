// Evenet listners for bottuns that will apear on different stages of chat
// state
import { showAlert } from '../reusables/alert.js';
import showError from '../reusables/showError.js';
import makeRequest from '../reusables/fetch.js';
import * as stages from './stages.js';
import prompts from './prompts.js';
import getAudio from '../speech.js';

class Chat {
  chatContainer = document.querySelector('.chatbox__support');
  messagesContainer = document.querySelector('.messages-chat');
  chatFooter = document.querySelector('.chatbox__footer');
  input = document.getElementById('user-input');
  btnGenerate = document.getElementById('submit-button');
  restartBtn = document.querySelector('.btn-restartchat');
  speechRobot = document.querySelector('.speech-robot');

  baseUrl = '/api/v1/products';
  chatUrl = '/api/v1/chat/completion';

  markUpIntermediateBtns = `<div class='btn-message-item messages__item' id={ID} data-product-id={PRODUCT_ID}>{CONTENT}</div>`;
  markUpInput = `<input id='user-input' type='text' placeholder='Demande-moi n'importe quoi...' />`;
  markUpGenerateBtn = `<span id='submit-button' class='chatbox__send--footer'><i class='fa-regular fa-paper-plane'></i></span>`;

  state = {
    stage: stages.REFERENCE_OR_NAME,
    lastUserInput: '',
    productId: '',
    history: [],
    products: [],
    audioElements: [],
    str: '',
  };

  constructor() {
    this.chatContainer && this.init();
  }

  // ------------ Handle Genereate
  handleGenerate = async (e) => {
    const value = this.input.value;

    // check value existance
    if (!value?.trim()) return showAlert('danger', 'Input must have value');

    // then try
    try {
      this.state.lastUserInput = value.trim();

      switch (this.state.stage) {
        // product reference handler
        case stages.PRODUCT_REFERENCE:
          await this.findProductByReference(value);
          break;

        // Product name handler
        case stages.PRODUCT_NAME:
          await this.findProductByName(value);
          break;

        // question handler
        case stages.PROCEED:
          this.proceedHandler(value);
          break;

        default:
          break;
      }
    } catch (err) {
      showError(err);
    }
  };

  // ------------- Fetch products by reference
  findProductByReference = async function (reference) {
    // check a product reference doesn't contain a space
    if (reference.trim().split(' ').length > 1) return showAlert('danger', 'Product reference must not have a space.');

    this.addUserMessage(reference.trim());

    const data = await makeRequest({
      url: `${this.baseUrl}/end-user/${reference.trim()}`,
    });

    this.state.products = data.data.products;

    if (data.results === 0) {
      this.foundNon();
    }

    if (data.results === 1) this.handleFoundOne();
  };

  // ------------- found one with product reference
  async handleFoundOne() {
    const product = this.state.products[0];

    const prompt = this.state.stage === stages.PRODUCT_REFERENCE ? prompts.foundOneByReference : prompts.foundOneByName;

    this.state.stage =
      this.state.stage === stages.PRODUCT_REFERENCE ? stages.FOUND_ONE_BY_REFERENCE : stages.FOUND_ONE_BY_NAME;

    this.addBotMessage(
      await this.rephraser(prompt.replace('{PRODUCT}', product.name).replace('{BRAND}', product.brand))
    );

    this.state.productId = product._id;
    this.renderIntermediateBtns();
  }

  // ------------- Fetch products by name
  async findProductByName(name) {
    this.addUserMessage(name.trim());

    const { results, data } = await makeRequest({
      url: `${this.baseUrl}/end-user`,
      dataTobeSent: { name },
      method: 'post',
    });

    // ---------- update products
    this.state.products = data.products;

    if (results > 1) {
      this.handleFoundMultipleWithName();
    }

    if (results === 1) {
      this.handleFoundOne();
    }

    if (results === 0) this.foundNon();
  }

  // ------------- handle multiple by name
  async handleFoundMultipleWithName() {
    this.state.stage = stages.FOUND_MULTIPLE_BY_NAME;
    const { products } = this.state;
    let prompt;

    const renderPrompt = async () => {
      const rephrased = await this.rephraser(prompt.replace('{ENTERED_NAME}', this.state.lastUserInput.toUpperCase()));
      this.addBotMessage(rephrased);
    };

    if (products.length <= 5) {
      prompt = prompts.foundFewByName;
      await renderPrompt();
    }

    if (products.length > 5 && products.length <= 15) {
      prompt = prompts.foundMultipleByName;
      await renderPrompt();
    }

    if (products.length > 15) {
      prompt = prompts.foundLotByName;
      await renderPrompt();
    }

    this.renderIntermediateBtns();
  }

  // ----------- Found non
  async foundNon() {
    let prompt;

    if (this.state.stage === stages.PRODUCT_REFERENCE) {
      prompt = prompts.foundNonByReference.replace('{REFERENCE}', this.state.lastUserInput);

      this.state.stage = stages.NON_FOUND_BY_REFERENCE;
    }

    if (this.state.stage === stages.PRODUCT_NAME) {
      prompt = prompts.foundNonByName.replace('{ENTERED_NAME}', this.state.lastUserInput);

      this.state.stage = stages.FOUND_NON_BY_NAME;
    }

    const rephrased = await this.rephraser(prompt);

    this.addBotMessage(rephrased);

    this.renderIntermediateBtns();
  }

  // --------------- WebSocket
  async proceedHandler(question) {
    this.addUserMessage(question.trim());

    if (this.socket.readyState !== WebSocket.OPEN) {
      this.resetWebsocket(question);

      return;
    }

    this.socket.send(JSON.stringify({ question, history: this.state.history }));

    //  make the input field inactive
    this.removeListnerFromInput();
    // try {
    //   // prepare options obj for request
    //   const opt = {
    //     url: `${this.chatUrl}/${this.state.productId}`,
    //     dataTobeSent: {
    //       question,
    //       history: this.state.history.slice(-5),
    //     },
    //     method: 'post',
    //   };

    //   // ask
    //   const { data } = await makeRequest(opt);

    //   // activate input field
    //   this.state.history.push([
    //     `Question: ${this.state.lastUserInput}`,
    //     `Answer: ${data.response.text}`,
    //   ]);

    //   // add bout message
    //   this.addBotMessage(data.response.text);
    // } catch (err) {
    //   // this.messagesContainer.querySelector('.chat-typingEffect')?.remove();

    //   const message = err.response
    //     ? err.response.data.message || err.response.data
    //     : err.message;
    //   showAlert('danger', message + ' Please Try Again.');
    // }
    // this.addListnerToInput();
  }

  //   User Message
  addUserMessage(message) {
    this.input && (this.input.value = '');

    const userDiv = document.createElement('div');
    userDiv.className = 'messages__item messages__item--user';
    userDiv.innerHTML = `${message}`;
    this.messagesContainer.appendChild(userDiv);

    // update ui height

    // update state for last user input
    this.state.lastUserInput = message;
    this.removeListnerFromInput();
    this.state.str = '';
    setTimeout(() => {
      this.addBotMessage('', true);
    }, 1500);
  }

  //   ------ Bot Message
  addBotMessage(resultText, typing) {
    this.messagesContainer.querySelector('.chat-typingEffect')?.remove();
    const botDiv = document.createElement('div');
    botDiv.className = typing ? 'chat-typingEffect' : 'messages__item messages__item--bot ';

    botDiv.innerHTML =
      typing === true
        ? `<p class='text-to-be-copy'></p>
          <div class='dots'>
            <span class='dot'></span><span class='dot'></span><span class='dot'></span>
          </div>`
        : markdownit().render(resultText) +
          `<div class="chatbox__speaker--container" >
              <i class="fa fa-volume-up" aria-hidden="true"></i>
            </div>`;

    typing || (botDiv.dataset.result = `${resultText}`);

    // botDiv.innerText = `${resultText}`;
    this.messagesContainer.appendChild(botDiv);
  }

  removeTypingEffect() {
    const lastBotMessage = this.messagesContainer.querySelector('.chat-typingEffect');
    lastBotMessage.classList.remove('chat-typingEffect');
    lastBotMessage.querySelector('.dots')?.remove();
    lastBotMessage.insertAdjacentHTML(
      'beforeend',
      `<div class="chatbox__speaker--container" >
              <i class="fa fa-volume-up" aria-hidden="true"></i>
      </div>`
    );

    this.addListnerToInput();
  }

  // ------------ Intermediate
  renderIntermediateBtns() {
    // Intermediate for prdouct_reference stage
    this.renderRemoveInputField({ remove: true });

    switch (this.state.stage) {
      case stages.REFERENCE_OR_NAME:
        this.referenceOrName();
        break;
      case stages.FOUND_ONE_BY_REFERENCE:
        this.foundOneIntermediate({ byName: false });
        break;

      case stages.NON_FOUND_BY_REFERENCE:
        this.foundNonIntermediate({ byName: false });
        break;

      case stages.FOUND_ONE_BY_NAME:
        this.foundOneIntermediate({ byName: true });
        break;

      case stages.FOUND_MULTIPLE_BY_NAME:
        this.multipleByName();
        break;

      case stages.FOUND_NON_BY_NAME:
        this.foundNonIntermediate({ byName: true });
        break;

      default:
        break;
    }
  }

  // --------------- Reference or Name
  referenceOrName() {
    const checkWithProductName = this.markUpIntermediateBtns
      .replace('{CONTENT}', 'trouver par nom')
      .replace('{ID}', 'check-by-name');

    const checkWithReference = this.markUpIntermediateBtns
      .replace('{CONTENT}', 'trouver par référence produit')
      .replace('{ID}', 'check-by-reference');

    this.renderWithTimeout(checkWithProductName, checkWithReference);
  }

  // ------------- Non by reference
  foundNonIntermediate({ byName = false }) {
    const checkWithProductName = this.markUpIntermediateBtns
      .replace('{CONTENT}', byName ? 'Mettre à jour le nom' : 'trouver par nom')
      .replace('{ID}', 'check-by-name');

    const checkWithAnotherProductReference = this.markUpIntermediateBtns
      .replace('{CONTENT}', 'trouver par référence produit')
      .replace('{ID}', 'check-by-reference');

    this.renderWithTimeout(checkWithProductName, checkWithAnotherProductReference);
  }

  // --------- found one intermediate handler
  foundOneIntermediate({ byName = false }) {
    const proceed = this.markUpIntermediateBtns.replace('{CONTENT}', 'procéder').replace('{ID}', 'proceed');

    const checkWithReference = this.markUpIntermediateBtns
      .replace('{CONTENT}', 'trouver par référence produit')
      .replace('{ID}', 'check-by-reference');

    const checkWithProductName = this.markUpIntermediateBtns
      .replace('{CONTENT}', byName ? 'Mettre à jour le nom' : 'trouver par nom')
      .replace('{ID}', 'check-by-name');

    this.renderWithTimeout(proceed, checkWithProductName, checkWithReference);
  }

  // ----------- Multiple by name
  multipleByName() {
    const updateProductName = this.markUpIntermediateBtns
      .replace('{CONTENT}', 'Mettre à jour le nom')
      .replace('{ID}', 'check-by-name');

    if (this.state.products.length <= 5) {
      this.renderWithTimeout(
        // map the product found
        ...this.state.products.map((product) => {
          return this.markUpIntermediateBtns
            .replace(
              '{CONTENT}',
              `${product.name} by ${product.brand?.toUpperCase()}. en référence: ${product.productReference}`
            )
            .replace('{ID}', 'product-choice')
            .replace('{PRODUCT_ID}', product._id);
        }),
        // ---- Allow users to update the name of the product
        updateProductName
      );
    }
  }

  // ------------ Handle Message Container CLick
  handleMessageClick = (e) => {
    const targetIntermediate = e.target.closest('.btn-message-item');
    const chatBotSpeaker = e.target.closest('.chatbox__speaker--container');
    if (targetIntermediate) return this.handleItermediateBtns(e);
    if (chatBotSpeaker) return this.handleSpeaker(e);
  };

  // ------------ HandleSpeaker
  handleSpeaker = async (e) => {
    const chatBotSpeaker = e.target.closest('.chatbox__speaker--container');

    // Get the text
    const textToSpeak = e.target.closest('.messages__item--bot').textContent;

    // Remove the active class from all the others and addd it to this one
    document.querySelectorAll('.chatbox__speaker--container').forEach((speaker) => {
      speaker.classList.remove('active');
    });
    chatBotSpeaker.classList.add('active');

    if (chatBotSpeaker.dataset.hasSource === 'yes') return;

    // let know this chatBotSpeaker has the audio data
    chatBotSpeaker.dataset.hasSource = 'yes';
    const audioElement = new Audio();

    // get Audio
    const blobSrc = await getAudio(textToSpeak);
    this.pauseAllAudios();

    // play audio and update state
    audioElement.src = blobSrc;
    audioElement.play();
    this.state.audioElements.push(audioElement);

    // Display the robot speaker
    this.speechRobot.classList.contains('active') || this.speechRobot.classList.add('active');

    // remove the active class when the audio ends playing
    audioElement.onended = () => {
      chatBotSpeaker.classList.remove('active');
      this.speechRobot.classList.remove('active');
    };

    // handle reclicking of the same audio button
    chatBotSpeaker.addEventListener('click', () => {
      audioElement.currentTime = 0;
      if (chatBotSpeaker.classList.contains('active')) {
        setTimeout(() => {
          chatBotSpeaker.classList.remove('active');
          this.speechRobot.classList.remove('active');
        }, 200);
        return audioElement.pause();
      }
      this.pauseAllAudios();

      audioElement.play();

      this.speechRobot.classList.contains('active') || this.speechRobot.classList.add('active');
    });
  };

  // ------------ Handle Intermediate Btns
  handleItermediateBtns = (e) => {
    const targetIntermediate = e.target.closest('.btn-message-item');
    if (!targetIntermediate) return;

    document.querySelectorAll('.btn-message-item').forEach((btn) => {
      targetIntermediate !== btn && btn.remove();
      targetIntermediate === btn &&
        (btn.classList.add('messages__item--user') || targetIntermediate.classList.remove('btn-message-item'));
    });

    switch (targetIntermediate.id) {
      case 'check-by-reference':
        this.handleByReferenceBtn();
        break;

      case 'check-by-name':
        this.handleCheckByNameBtn();
        break;

      case 'proceed':
        this.handleProceedBtn();
        break;

      case 'product-choice':
        this.handleProductChoiceBtn(targetIntermediate);
        break;

      default:
        break;
    }
  };

  handleByReferenceBtn() {
    this.state.stage = stages.PRODUCT_REFERENCE;

    setTimeout(() => {
      this.addBotMessage(prompts.provideReference);
      setTimeout(() => {
        this.renderRemoveInputField({ render: true });
      });
    }, 300);
  }

  // ----------- INTERMEDIATE BTN CLICK HANDLERS
  handleCheckByNameBtn() {
    // ---------- Update State
    this.state.stage = stages.PRODUCT_NAME;

    this.addRephrasedAndEnableInput(prompts.provideName);
  }

  // ----------- Found one and proceed intermediate btn
  async handleProceedBtn() {
    // ------------ Update State
    this.state.stage = stages.PROCEED;

    this.setWebsocket();

    await makeRequest({
      url: `${this.baseUrl}/end-user/update-stat/${this.state.productId}`,
    });

    this.addRephrasedAndEnableInput(prompts.proceedWithProduct);
  }

  // ------- intermediates for less than five products by name
  async handleProductChoiceBtn(choice) {
    // ------------ Update state
    this.state.stage = stages.PROCEED;
    this.state.productId = choice.dataset.productId;
    this.state.products = [];

    this.setWebsocket();

    await makeRequest({
      url: `${this.baseUrl}/end-user/update-stat/${this.state.productId}`,
    });

    this.addRephrasedAndEnableInput(prompts.proceedWithProduct);
  }

  setWebsocket() {
    this.socketUrl = `wss://${location.hostname}${location.port ? ':' + location.port : ''}/api/v1/chat/streaming/${
      this.state.productId
    }`;

    this.socket = new WebSocket(this.socketUrl);

    this.socket.onmessage = this.addWebsocketResponse;
    this.socket.onclose = this.handleSocketClose;
  }

  // ---------- Render intermediates with timeout
  renderWithTimeout(...markups) {
    setTimeout(() => {
      markups.forEach((mar) => {
        this.messagesContainer.insertAdjacentHTML('beforeend', mar);
      });
    }, 300);
  }

  //   -------- Render Remove Input Field along with the Btn
  renderRemoveInputField({ render, remove }) {
    if (render) {
      this.input.removeAttribute('disabled');
      this.addListnerToInput();
      this.input.placeholder = "Demande-moi n'importe quoi...";
      this.input.focus();
    }

    if (remove) {
      this.removeListnerFromInput();
      this.input.setAttribute('disabled', true);
      this.input.placeholder = 'Veuillez en sélectionner un pour continuer...';
      // this.chatFooter.classList.add('hidden');
    }
    // remove && this.chatFooter.remove();
  }

  //   ---------- send handler
  handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      this.handleGenerate(e);
    }
  };

  //   ---------- Input Field eventListner adder
  addListnerToInput() {
    this.input.addEventListener('keyup', this.handleEnterKey);
    this.btnGenerate.addEventListener('click', this.handleGenerate);
  }

  //   ---------- Input Field eventListner remover
  removeListnerFromInput() {
    this.input.removeEventListener('keyup', this.handleEnterKey);
    this.btnGenerate.removeEventListener('click', this.handleGenerate);
  }

  // ------------  Init
  init() {
    this.addListnerToInput();
    this.addBotMessage(prompts.hello);
    this.renderIntermediateBtns();

    this.messagesContainer.addEventListener('click', this.handleMessageClick);

    this.restartHandler = this.restart.bind(this);
    this.restartBtn.addEventListener('click', this.restartHandler);
  }

  // ---------- Restart
  restart() {
    // Remove listners
    this.removeListnerFromInput();
    this.messagesContainer.removeEventListener('click', this.handleMessageClick);
    this.restartBtn.removeEventListener('click', this.restartHandler);
    this.pauseAllAudios();

    document.querySelectorAll('.btn-message-item')?.forEach((btnInter) => {
      btnInter.remove();
    });

    // Update state
    this.state = {
      stage: stages.REFERENCE_OR_NAME,
      lastUserInput: '',
      productId: '',
      history: [],
      products: [],
      audioElements: [],
      str: '',
    };

    this.socket = new WebSocket(this.socketUrl);
    this.socket.onmessage = this.addWebsocketResponse;
    this.socket.onclose = this.handleSocketClose;

    delete this.socket;
    delete this.socketUrl;

    // Initialise the chat
    this.init();
  }

  addWebsocketResponse = (event) => {
    const message = JSON.parse(event.data);
    const lastBotMessage = this.messagesContainer.querySelector('.chat-typingEffect');
    if (message.event === 'data') {
      if (this.state.str === undefined || this.state.str === '') {
        lastBotMessage.classList.add('messages__item');
        lastBotMessage.classList.add('messages__item--bot');
        lastBotMessage.querySelector('.dots')?.remove();
      }
      this.state.str += message.data === undefined ? '' : message.data;
      this.state.str === 'undefined' && (this.state.str = '');
      lastBotMessage.querySelector('.text-to-be-copy').innerHTML = window.markdownit().render(this.state.str);

      this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight;
    }

    if (message.event === 'end') {
      this.state.str = '';
      this.state.history.push([`Question: ${this.state.lastUserInput}`, `Answer: ${message.data.text}`]);
      this.removeTypingEffect();
    }

    if (message.event === 'error') {
      this.removeListnerForInput();
      this.addListnersForInput();
      showAlert('danger', message.statusCode === 500 ? 'Something Went wrong. Please Try again!' : message.error);

      return console.log(message.error);
    }
  };

  resetWebsocket(question) {
    this.socket = new WebSocket(this.socketUrl);
    this.socket.onmessage = this.addWebsocketResponse;
    this.socket.onclose = this.handleSocketClose;

    setTimeout(() => {
      this.socket.readyState === WebSocket.OPEN &&
        this.socket.send(JSON.stringify({ question, history: this.state.history }));
      if (this.socket.readyState === WebSocket.CLOSED || this.socket.readyState === WebSocket.CLOSING) {
        showAlert('danger', 'Something went wrong on trying to connect to the websocket. please try again');
        setTimeout(() => {
          location.reload(true);
        }, 1000);
      }
    }, 3000);
  }

  // pause all other audios helper function
  pauseAllAudios() {
    this.state.audioElements.forEach((aud) => {
      aud.pause();
      aud.currentTime = 0;
    });
  }

  handleSocketClose = () => {
    console.log('disconnected. Trying to reconnect...');
    this.state.str = '';

    if (document.querySelector('.chat-typingEffect')) {
      showAlert('danger', 'websocket disconnected while streamin resul.');
      document.querySelector('.chat-typingEffect').remove();
    }

    this.removeListnerFromInput();
    this.addListnerToInput();

    this.socket = new WebSocket(this.socketUrl);
    this.socket.onmessage = this.addWebsocketResponse;
    this.socket.onclose = this.handleSocketClose;
  };

  // ------------- rephraser
  async rephraser(prompt) {
    const { data } = await makeRequest({
      method: 'post',
      url: `${this.baseUrl}/end-user/rephrase`,
      dataTobeSent: {
        prompt,
      },
    });

    return data.rephrased;
  }

  async addRephrasedAndEnableInput(prompt) {
    // --------- Insert typing effect
    this.addBotMessage('', true);

    // ------------- Render the promts and also the input fields
    const rephrasedPrompt = await this.rephraser(prompt);

    this.addBotMessage(rephrasedPrompt);

    setTimeout(() => {
      this.renderRemoveInputField({ render: true });
    }, 500);
  }
}

export default new Chat();
