import { showAlert } from './alert.js';

export function showProgress(btn, content) {
  // console.log(content);
  btn.setAttribute('disabled', true);
  btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>${
    content ? content : 'Loading...'
  }`;
}

export function removeProgress(btn, innerHTML) {
  btn.innerHTML = innerHTML;
  btn.removeAttribute('disabled');
}

export function showSuccessUpdate(btnUpdate) {
  // Show Success Alert
  showAlert('success', 'Update Successful');
  removeProgress(btnUpdate, 'Updated');
  btnUpdate.classList.remove('btn-primary');
  btnUpdate.classList.add('btn-success');

  // Remove the green
  setTimeout(() => {
    btnUpdate.classList.remove('btn-success');
    btnUpdate.classList.add('btn-primary');
    btnUpdate.innerHTML = 'Update';
  }, 3000);
}
