import showError from '../reusables/showError.js';
import makeRequest, { getClientDocs, getDocs } from '../reusables/fetch.js';

import {
  generateClientMarkup,
  generateDashboardMarkup,
  generateProductMarkup,
  thead,
  markupLoading,
  markupTableContainer,
  // generateStatMarkup,
  markupPage,
} from './markups.js';
import { generateGetChatBotMarkup } from '../client/dashboardMarkups.js';
import initStat from '../client/requestStat.js';
import { initProductManagment } from './manageProducts.js';
import { handleSearchDocs } from './searchDocs.js';
import handlePagination from './paginate.js';

const baseUrl = '/api/v1';
let parentElement;
let manage;
let client;

const navigationContainer = document.querySelector(
  '.navigation--side--bar__links__container'
);

async function renderDocs() {
  try {
    let rowMarkups;
    let cssLink, scriptTag, markDown, scriptTagBig, cssLinkBig;
    let page, numOfPages, docs;

    if (manage === 'users' || manage.includes('products')) {
      let data;
      if (manage === 'users' || manage === 'products') data = await getDocs({ manage });

      if (manage.includes('-products')) data = await getClientDocs({ manage });

      page = data.page;
      numOfPages = data.numOfPages;
      docs = data.products || data.documents;

      parentElement
        .closest('.table-responsive')
        ?.insertAdjacentHTML(
          'beforeend',
          markupPage({ page, numOfPages, docType: manage })
        );
    }

    const newManage = manage;

    switch (manage) {
      case 'users':
        rowMarkups = docs.map((doc, i) => generateClientMarkup({ doc, i })).join('');
        break;
      case 'products':
        rowMarkups = docs
          .map((product, i) => generateProductMarkup({ doc: product, i }))
          .join('');
        break;
      case 'client-products':
        rowMarkups = docs
          .map((product, i) =>
            generateProductMarkup({ doc: product, i, isOnClient: true })
          )
          .join('');
        break;
      case 'add-products':
        rowMarkups = docs
          .map((product, i) => generateProductMarkup({ doc: product, i, toClient: true }))
          .join('');
        break;
      case 'most-searched':
        rowMarkups = `<h5 id='total-queries'></h5><div class='chart-container'><canvas id='chart'></canvas></div>`;
        break;
      case 'generate-chatbot': {
        const {
          data: { data },
        } = await makeRequest({
          url: `${baseUrl}/users/get${client ? '-client-' : ''}tags/${
            client ? client._id : ''
          }`,
        });

        cssLink = data.css;
        scriptTag = data.script;
        markDown = data.markDown;

        cssLinkBig = data.cssLinkBig;
        scriptTagBig = data.scriptTagBig;

        rowMarkups =
          (scriptTagBig ? generateGetChatBotMarkup(true) : '') +
          generateGetChatBotMarkup();
        break;
      }
      case 'dashboard':
        parentElement.classList.add('admin--dashboard__container');
        rowMarkups = (await getStats())
          .map((stat) => generateDashboardMarkup(stat))
          .join('');
        break;

      default:
        return;
        break;
    }

    // Render rows
    if (manage !== newManage) return;

    parentElement.insertAdjacentHTML('afterbegin', rowMarkups);

    document
      .querySelector('.input-search-autocomplate')
      ?.addEventListener('input', handleSearchDocs);

    manage === 'most-searched' && (await initStat(client));

    manage === 'generate-chatbot' &&
      parentElement.querySelectorAll('.tag')?.forEach((link) => {
        link.textContent = link.textContent.replace('{MARK_DOWN}', markDown);
        link.textContent = link.textContent.replace('{CSS_LINK}', cssLink);
        link.textContent = link.textContent.replace('{CSS_LINK_BIG}', cssLinkBig);
        link.textContent = link.textContent.replace('{SCRIPT_TAG_BIG}', scriptTagBig);
        link.textContent = link.textContent.replace('{SCRIPT_TAG}', scriptTag);
        link.textContent = link.textContent.replace(
          '{HTML_BUBBLE_CHATBOT}',
          "<section class='bubble-chatBot'></section>"
        );
      });

    // ------------ initialize pagination handler
    document.getElementById('paginate-documents') && initPagination(manage);

    // ---------------- Initialize product managment
    (manage === 'products' ||
      manage === 'client-products' ||
      manage === 'add-products') &&
      initProductManagment();
    // Remove Loading effecct
    setTimeout(() => {
      document.querySelector('.loading-container')?.remove();
    });
  } catch (err) {
    showError(err);
  }
}

// --------------- Get Stats
async function getStats() {
  const { data } = await makeRequest({
    url: `${baseUrl}/products/get-stats`,
  });

  return data.stats;
}

// -------------- Pagination
async function initPagination(docType) {
  if (docType !== manage) return;
  const paginationContainer = document.getElementById('paginate-documents');

  paginationContainer.removeEventListener('click', handlePagination);
  paginationContainer.addEventListener('click', handlePagination);
}

// ----------- Init
async function render(parentContainer, titleElement, id) {
  manage = id;
  const markupManger = id.includes('-') ? id.split('-')[1].toLowerCase() : id;

  client =
    navigationContainer.dataset.client !== 'undefined'
      ? JSON.parse(navigationContainer.dataset.client)
      : undefined;

  const titleExtension = id
    .split('-')
    .map((str) => str.slice(0, 1).toUpperCase() + str.slice(1))
    .join(' ');
  titleElement.innerHTML = client
    ? `Manage ${client.name}` + `<p>${titleExtension}</p>`
    : titleExtension;

  // ---------- Reset parent
  parentContainer.innerHTML = '';
  parentContainer.classList.remove('admin--dashboard__container');
  parentContainer.classList.remove('stastics-main-container');
  parentContainer.classList.remove('main-body-section__content');
  parentContainer.classList.remove('item-main-container');
  const clientId = document.body.dataset.userId;

  //  Add Loading effect
  parentContainer.insertAdjacentHTML('beforeend', markupLoading);

  // prepare the parent container for non dashbaord
  if (markupManger === 'products' || markupManger === 'users') {
    const opts =
      manage === 'add-products'
        ? { toClient: true }
        : manage === 'client-products'
        ? { isOnClient: true }
        : {};

    parentContainer.innerHTML = await markupTableContainer(markupManger, opts);
  }

  if (id === 'dashboard') {
    // prepare the parent container for the dashboard
    parentContainer.classList.add('admin--dashboard__container');
  }

  // remove the stat class from the container
  parentContainer.classList[id === 'most-searched' ? 'add' : 'remove'](
    'stastics-main-container'
  );

  parentElement = document.getElementById(`table-${markupManger}`) || parentContainer;

  renderDocs();
}

export default render;
