export const REFERENCE_OR_NAME = 'REFERENCE_OR_NAME';

// -------------- Reference
export const PRODUCT_REFERENCE = 'PRODUCT_REFERENCE';
export const NON_FOUND_BY_REFERENCE = 'NON_FOUND_BY_REFERENCE';
export const FOUND_ONE_BY_REFERENCE = 'FOUND_ONE_BY_REFERENCE';
export const FOUND_MULTIPLE_BY_REFERENCE = 'FOUND_MULTIPLE_BY_REFERENCE';

// ----------------- Proceed
export const PROCEED = 'PROCEED';

// --------------------- Name
export const PRODUCT_NAME = 'PRODUCT_NAME';
export const FOUND_MULTIPLE_BY_NAME = 'FOUND_MULTIPLE_BY_NAME';
export const FOUND_NON_BY_NAME = 'FOUND_NON_BY_NAME';
export const FOUND_ONE_BY_NAME = 'FOUND_ONE_BY_NAME';

// -------------------- BRAND
export const PRODUCT_BRAND = 'PRODUCT_BRAND';
