export function generateGetChatBotMarkup(isBig = false) {
  return isBig
    ? `<div class='link-container'>
        <div class='link-label-container copy-box'>
            <p class='label'>Big ChatBot HTML Head Tags</p>
            <div class='copy-content link'>
                <p class='tag'>{MARK_DOWN}</p>
                <p class='tag'>{SCRIPT_TAG_BIG}</p>
                <span class='copy-status'><i class='fa-regular fa-copy'></i> Copy</span>
            </div>
            <p class='label'>Place the following HTML code in your site where you want to put the big ChatBot</p>
            <div class='copy-content link'>
            <p class='tag'>{HTML_BUBBLE_CHATBOT}</p>
            </div>
        </div>
    </div>`
    : `
    <div class='link-container'>
        <div class='link-label-container copy-box'>
            <p class='label'>Floating ChatBot HTML Head Tags</p>
            <div class='copy-content link'>
                <p class='tag'>{MARK_DOWN}</p>
                <p class='tag'>{SCRIPT_TAG}</p>
                <span class='copy-status'><i class='fa-regular fa-copy'></i> Copy</span>
            </div>
        </div>
    </div>
    
    <div class='message-box'>
        <h4>
            <i class='fas fa-info-circle'></i> Guide: Embedding a Floating Chatbot into Your Website
        </h4>
        <p>
             Adding a floating chatbot to your website can enhance user engagement and provide valuable assistance. By following these steps, you can seamlessly integrate a chatbot using a CSS link and script tag.
        </p>
        <ul>
            <li>
                 Get Your Chatbot Script: Before you start, make sure you have the necessary chatbot script that you want to integrate into your website. This script contains the logic and functionality of the chatbot.
            </li>
            <li>
               <b> Embed the Markdown Tag:</b> <p class='tag'> {MARK_DOWN}</p>
            </li>
            <li>
                <b> Embed the Script Tag: </b> <p class='tag'> {SCRIPT_TAG}</p>
            </li>
        </ul>
    </div>
    `;
}
